<template>
    <div>
        <button style="display: flex; background-color: white; align-items: center; margin-left:1rem; margin-top:4px; border: none" title="Regresar" v-on:click="comeback()"><box-icon name='arrow-back'></box-icon></button>
    </div>
        <div class="flex p-3" style="height: 100vh; flex:2;" v-for="(item, i) in dataPostulate" :key="i + 1">
            <div style="display: flex; background-color:#835ca3; padding: 1rem; align-items: center; min-height: 200px;">
                <div v-if="item.user?.photo" style="width: 10%; display: flex; justify-content: center;">
                    <img class="rounded-circle" :src="item.user.photo?.link" alt="perfil" loading="eager"
                        style="width: 100%; aspect-ratio: 1/1; object-fit: cover; object-position: center;">
                </div>
                <div v-else style="width: 10%; margin: 10px;"> 
                            <img src="/images/profile.jpg" alt="Logo Company" class="rounded-circle" loading="eager"
                        style="width: 90%; aspect-ratio: 1/1; object-fit: cover; object-position: center;">
                        </div>
                &nbsp;
                &nbsp;
                <div
                    style="display: flex; width: 90%; flex-direction: column; justify-content: left; align-content: center;">
                    <span
                        style="font-size: 20px; font-weight: 500; color: white; font-family: Poppins; text-transform: capitalize;">
                        {{ item.student.name }}
                        {{ item.student.lastname }}
                        {{ item.student.secondlastname }}
                    </span>
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                        <div style="flex: 1;" v-show="item.student.status === 'exuniversitario'">
                            <span style="font-size: 15px; font-weight: 500; color: white; font-family: Poppins;">
                                Profesionista
                            </span>
                            <small style="color:white"> / </small>
                            <span style="font-size: 15px; font-weight: 500; color: white; font-family: Poppins;">
                                {{ getEdad(item.student.date) }} años
                            </span>
                        </div>
                        <div style="flex: 1; display:flex; justify-content: flex-end;">
                            <div v-if="item.student.linkedin">
                                <a style="cursor: pointer;" title="LinkedIn" target="_blank"
                                    v-on:click="openLinkedin(item.student.linkedin)">
                                    <i class="fab fa-linkedin-in" style="color: white;"></i>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- Nueva navegación -->
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="documents-tab" data-bs-toggle="tab"
                        data-bs-target="#subir-tab-pane" type="button" role="tab" aria-controls="subir-tab-pane"
                        aria-selected="true">Datos de
                        Administración</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane"
                        type="button" role="tab" aria-controls="home-tab-pane" aria-selected="false">Datos
                        Personales</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane"
                        type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Información
                        Escolar</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="experiencia-tab" data-bs-toggle="tab"
                        data-bs-target="#experiencia-tab-pane" type="button" role="tab"
                        aria-controls="experiencia-tab-pane" aria-selected="false">Experiencia</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane"
                        type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Idiomas</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="disabled-tab" data-bs-toggle="tab" data-bs-target="#disabled-tab-pane"
                        type="button" role="tab" aria-controls="disabled-tab-pane"
                        aria-selected="false">Hobbies</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="documents-tab" data-bs-toggle="tab"
                        data-bs-target="#documents-tab-pane" type="button" role="tab" aria-controls="documents-tab-pane"
                        aria-selected="false">Documentos
                        Empleado</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="documents-tab" data-bs-toggle="tab"
                        data-bs-target="#convenios-tab-pane" type="button" role="tab" aria-controls="convenios-tab-pane"
                        aria-selected="false">Documentos
                        Empresa</button>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <!-- Datos de Administracion -->
                <div class="tab-pane fade show active" id="subir-tab-pane" role="tabpanel" aria-labelledby="subir-tab"
                    tabindex="7" style="overflow-y: scroll; overflow-x: hidden;">
                    <div style="display: flex; flex-direction: column; padding: 3%; height: 100vh" v-if="this.enableInput">
                        <div style="display: flex; justify-content: space-between; flex-direction: row; margin-bottom: 2%;">
                        <div>
                            <span style="font-size: 20px; font-family: Poppins;">
                                Datos de Administración
                            </span>
                        </div>
                            <div style="display: flex; justify-content: center; align-items: center;">
                                <box-icon name='edit' v-on:click="editInf()" style="cursor: pointer;"></box-icon>
                            </div>
                        </div>
                        <div class="row" style="display: flex; flex-wrap: wrap; gap: 1rem; height: 4rem;">
                            <div style="flex: 1;">
                                <span><b>ID:</b> {{ item.idAdministration }}</span>
                            </div>
                            <div style="flex: 1;">
                                <span><b>Posicion:</b> {{ item.position }}</span>
                            </div>
                            <div style="flex: 1;">
                                <span><b>Nivel:</b> {{ item.level }}</span>
                            </div>
                        </div>
                        <div class="row" style="display: flex; flex-wrap: wrap; gap: 1rem; height: 4rem;">
                            <div style="flex: 1;">
                                <span><b>Unidad/División:</b> {{ item.company }}</span>
                            </div>
                            <div style="flex: 1;">
                                <span><b>Departamento:</b> {{ item.department }}</span>
                            </div>
                            <div style="flex: 1;">
                                <span><b>Correo Corporativo: </b>{{ item.emailCorporative }}</span>
                            </div>
                        </div>
                        <div class="row" style="display: flex; flex-wrap: wrap; gap: 1rem; height: 4rem;">
                            <div style="flex: 1;">
                                <span><b>Sueldo Mensual: </b>{{ item.salary }}</span>
                            </div>
                            <div style="flex: 1;">
                                <span><b>RFC:</b> {{ item.rfc }}</span>
                            </div>
                            <div style="flex: 1;">
                                <span><b>NSS:</b> {{ item.nss }}</span>
                            </div>
                        </div>
                        <div class="row" style="display: flex; flex-wrap: wrap; gap: 1rem; height: 4rem;">
                            <div style="flex: 1;">
                                <span><b>Banco:</b> {{ item.bank }}</span>
                            </div>
                            <div style="flex: 1;">
                                <span><b>Cuenta:</b> {{ item.bankAccount }}</span>
                            </div>
                            <div style="flex: 1;">
                                <span><b>Crédito Infonavit:</b> {{ item.infonavitCredit }}</span>
                            </div>
                        </div>
                        <div class="row" style="display: flex; flex-wrap: wrap; gap: 1rem; height: 4rem;">
                            <div style="flex: 1;">
                                <span><b>Status:</b> {{ item.statusActually }}</span>
                            </div>
                            <div style="flex: 1;">
                                <span><b>Antigüedad: </b>{{ getAntiguedad(item.dateHired, item.dateSecondPeriod) }} días</span>
                            </div>
                            <div style="flex: 1;"></div>
                        </div>
                        <div class="row" style="display: flex; flex-wrap: wrap; gap: 1rem; height: 4rem;">
                                <div v-show="!item.dateHired" style="flex: 1"><b>Fecha de ingreso:</b></div>
                                <div v-show="item.dateHired" style="flex: 1">
                                <span style="flex: 1"><b>Fecha de ingreso:</b>
                                    {{ moment.utc(item.dateHired).format('DD/MM/YYYY').split('T')[0] }}
                                </span>
                                </div>
                                <div v-show="!item.dateFirstPeriod" style="flex: 1"><b>Termino periodo de prueba:</b></div>
                                <div v-show="item.dateFirstPeriod" style="flex: 1">
                                    <span style="flex: 1">
                                        <b>Termino periodo de prueba:</b>
                                        {{ moment.utc(item.FirstPeriod).format('DD/MM/YYYY').split('T')[0] }}
                                    </span>
                                </div>
                                <div v-show="!item.dateSecondPeriod" style="flex: 1"><b>Fecha de baja:</b></div>
                                <div v-show="item.dateSecondPeriod" style="flex: 1">
                                    <span style="flex: 1">
                                        <b>Fecha de Baja:</b>
                                        {{ moment.utc(item.dateSecondPeriod).format('DD/MM/YYYY').split('T')[0] }}
                                    </span>
                                </div>
                                <hr>
                                <span style="font-size: 16px; font-family: Poppins; margin-bottom: 1rem"><b>Contactos de emergencia </b></span>
                                <div style="display: flex; width: 100%; height: 4rem">
                                    <span style="flex: 1"><b>Nombre: </b>{{ item.nameContact }}</span>
                                    <span style="flex: 1"><b>Parentesco: </b>{{ item.relationship }}</span>
                                    <span style="flex: 1"><b>Teléfono:</b> {{ item.numberContact }}</span>
                                </div>
                                <div style="display: flex; width: 100%; height: 4rem">
                                    <span style="flex: 1"><b>Nombre: </b>{{ item.nameContactTwo }}</span>
                                    <span style="flex: 1"><b>Parentesco: </b>{{ item.relationshipTwo }}</span>
                                    <span style="flex: 1"><b>Teléfono:</b> {{ item.numberContactTwo }}</span>
                                </div>
                                <hr />
                                <div style="display: flex; width: 100%;">
                                    <span class="flex"><b>Comentarios: </b>
                                        <span v-html="item.comments"></span>
                                    </span>
                                </div>
                        </div>
                    </div>
                    <div class="shadow p-3 mb-5 bg-body-tertiary rounded h-full" v-else-if="!this.enableInput">
                        <h5 class="mb-5">Datos de Administración</h5>
                        <form @submit.prevent="DataAdministration()" class="row"
                            style="display: flex; flex-direction:column; overflow-y: scroll !important;">
                            <div style="display: flex; flex-wrap: wrap; gap: 1rem; justify-content: center;">
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="floatingInput" min="5" max="255"
                                        placeholder="Nombre" autocomplete="off" v-model="idAdministration">
                                    <label for="floatingInput">ID</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="floatingInput" min="5" max="255"
                                        placeholder="Nombre" autocomplete="off" v-model="position">
                                    <label for="floatingInput">Posición</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="floatingInput" min="5" max="255"
                                        placeholder="Nombre" autocomplete="off" v-model="level">
                                    <label for="floatingInput">Nivel</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="floatingInput" min="5" max="255"
                                        placeholder="Nombre" autocomplete="off" v-model="company">
                                    <label for="floatingInput">Unidad/División</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="floatingInput" min="5" max="255"
                                        placeholder="Nombre" autocomplete="off" v-model="department">
                                    <label for="floatingInput">Departamento</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="floatingInput" min="5" max="255" placeholder="Nombre" autocomplete="off" v-model="emailCorporative" />
                                <label for="floatingInput">Correo corporativo</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="floatingInput" @keypress="onlyNumber" v-model="salary" @blur="formatBeca" placeholder="0.00" required autocomplete="off" />
                                    <label for="floatingInput">Sueldo Mensual</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="floatingInput" min="5" max="255"
                                        placeholder="Nombre" autocomplete="off" v-model="rfc">
                                    <label for="floatingInput">RFC</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="floatingInput" min="5" max="255"
                                        placeholder="Nombre" autocomplete="off" v-model="nss">
                                    <label for="floatingInput">NSS</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="floatingInput" min="5" max="255" placeholder="Nombre" autocomplete="off" v-model="infonavitCredit" />
                                <label for="floatingInput">Crédito Infonavit</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="floatingInput" min="5" max="255" placeholder="Nombre" autocomplete="off" v-model="statusActually" />
                                <label for="floatingInput">Status</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="floatingInput" min="5" max="255"
                                        placeholder="Nombre" autocomplete="off" v-model="bank">
                                    <label for="floatingInput">Banco</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="floatingInput" min="5" max="255"
                                        placeholder="Nombre" autocomplete="off" v-model="bankAccount">
                                    <label for="floatingInput">Cuenta Bancaria</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                            </div>
                            <div class="col-md-3">
                            </div>
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <input type="date" class="form-control" id="dateHiredInput" placeholder="Fecha de Ingreso" autocomplete="off" v-model="dateHired">
                                    <label for="dateHiredInput">Fecha de Ingreso</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <input type="date" class="form-control" id="dateFirstPeriodInput" placeholder="Período de prueba" autocomplete="off" v-model="dateFirstPeriod">
                                    <label for="dateFirstPeriodInput">Termino Periodo de Prueba</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <input type="date" class="form-control" id="dateSecondPeriodInput" placeholder="Segundo período" autocomplete="off" v-model="dateSecondPeriod">
                                    <label for="dateSecondPeriodInput">Fecha de Baja</label>
                                </div>
                            </div>
                            <!-- <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <input type="text" class="form-control" id="floatingInput" min="5" max="255" placeholder="Nombre" autocomplete="off" v-model="antiquityPractices" />
                                    <label for="floatingInput">Antigüedad</label>
                                </div>
                            </div> -->
                            <div class="col-md-3">
                            </div>
                            <div class="col-md-3">
                            </div>
                            </div>
                            <h6 style="padding: 1rem">Contactos de emergencia</h6>
                            <div style="display: flex; flex-wrap: wrap; gap: 1rem; justify-content: center">
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="floatingInput" min="5" max="255" placeholder="Nombre" autocomplete="off" v-model="nameContact" />
                                <label for="floatingInput">Nombre</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="floatingInput" min="5" max="255" placeholder="Nombre" autocomplete="off" v-model="relationship" />
                                <label for="floatingInput">Parentesco</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                <input type="phone" class="form-control" id="floatingInput" min="5" max="255" placeholder="Nombre" autocomplete="off" v-model="numberContact" />
                                <label for="floatingInput">Teléfono de contacto</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="floatingInput" min="5" max="255" placeholder="Nombre" autocomplete="off" v-model="nameContactTwo" />
                                <label for="floatingInput">Nombre</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                <input type="text" class="form-control" id="floatingInput" min="5" max="255" placeholder="Nombre" autocomplete="off" v-model="relationshipTwo" />
                                <label for="floatingInput">Parentesco</label>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                <input type="phone" class="form-control" id="floatingInput" min="5" max="255" placeholder="Nombre" autocomplete="off" v-model="numberContactTwo" />
                                <label for="floatingInput">Teléfono de contacto</label>
                                </div>
                            </div>
                            </div>
                            <h6 style="padding: 1rem">Comentarios</h6>
                            <div style="display: flex; flex-wrap: wrap; gap: 1rem; justify-content: center; margin-bottom: 65px;">
                                <div class="col-md-9">
                                    <QuillEditor id="editor" theme="snow" placeholder="Comentarios" v-model:content="comments" contentType="html"
                                    :toolbar="['bold', 'italic', 'underline', { 'list': 'ordered' }, { 'list': 'bullet' }, { 'align': [] }]" />
                                </div>
                            </div>
                            <div class="d-grid gap-2 col-6 mx-auto">
                                <button class="btn btn-primary" :disabled="response.loading" type="submit">
                                    Guardar o actualizar información
                                    <div v-show="response.loading" class="spinner-border spinner-border-sm" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div>
                                </button>
                                <button id="change" class="btn btn-danger me-md-2" type="button" v-on:click="editInf()">
                                    Cancelar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- Información Personal -->
                <div class="tab-pane fade" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                    <div style="padding: 2rem;">
                        <div style="display: flex; flex-wrap: wrap;">
                            <div style="display: flex; width: 100%; height: 4rem;">
                                <span style="flex: 1;"><b>Nombre:</b> 
                                    {{ item.student.name }}
                                    {{ item.student.lastname }}
                                    {{ item.student.secondlastname }}
                                </span>
                                <span style="flex: 1;" v-if="item.student.date"><b>Edad:</b> {{ getEdad(item.student.date) }} años</span>
                                <span style="flex: 1;"><b>Fecha de Nacimiento:</b> 
                                    {{ moment(item.student.date).format('DD/MM/YYYY') }} 
                                </span>
                            </div>
                            <div style="display: flex; width: 100%; height: 4rem;">
                                <span style="flex: 1;"><b>Género:</b> {{ item.student.gender }}</span>
                                <span style="flex: 1;"><b>Celular:</b> {{ item.student.phone }}</span>
                                <span style="flex: 1;"><b>Email:</b> {{ item.student.email }}</span>
                            </div>
                            <div style="display: flex; width: 100%; height: 4rem;">
                                <span style="flex: 1;"><b>Estado:</b> {{ item.student.estadoRepublica }}</span>
                                <span style="flex: 1;"><b>Municipio/Delegación:</b> {{ item.student.municipaly }}</span>
                                <span style="flex: 1;"><b>Colonia:</b> {{ item.student.delegation }}</span>
                            </div>
                            <div style="display: flex; width: 100%; height: 4rem;">
                                <span style="flex: 1;"><b>Pais:</b> {{ item.student.country }}</span>
                                <span style="flex: 1;"><b>CURP:</b> {{ item.student.curp }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Información Escolar -->
                <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab"
                    tabindex="0">
                    <div class="flex mt-3" style="padding: 1rem;">
                        <div v-if="item.student.status == 'exuniversitario'">
                            <div style="display: flex; flex-wrap: wrap;"
                                v-show="item.student.status === 'exuniversitario'">

                                <div class="row">
                                    <div class="text-justify m-2" v-if="item.student.schoolLic">
                                        <b>Escuela de Licenciatura: </b> {{ item.student.schoolLic }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="text-justify m-2" v-if="item.student.careerLic">
                                        <b>Nombre de la carrera: </b> {{ item.student.careerLic }}
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="text-justify m-2" v-if="item.student.startDateLic">
                                        <b>Inicio
                                            de
                                            la
                                            carrera: </b> {{ item.student.startDateLic }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="text-justify m-2" v-if="item.student.endDateLic">
                                        <b>Termino
                                            de la
                                            carrera: </b> {{ item.student.endDateLic }}
                                    </div>
                                </div>

                                <!-- Maestría -->

                                <div class="row" v-show="item.student.schoolMa">
                                    <div class="text-justify m-2" v-if="item.student.schoolMa"><b>Escuela
                                            de
                                            Licenciatura: </b> {{ item.student.schoolMa }} </div>
                                </div>
                                <div class="row" v-show="item.student.schoolMa">
                                    <div class="text-justify m-2" v-if="item.student.careerMa"><b>Nombre
                                            de la
                                            carrera: </b> {{ item.student.careerMa }} </div>
                                </div>


                                <div class="row" v-show="item.student.schoolMa">
                                    <div class="text-justify m-2" v-if="item.student.startDateMa">
                                        <b>Inicio
                                            de la
                                            carrera: </b> {{ item.student.startDateMa }}
                                    </div>
                                </div>
                                <div class="row" v-show="item.student.schoolMa">
                                    <div class="text-justify m-2" v-if="item.student.endDateMa">
                                        <b>Termino de
                                            la
                                            carrera: </b> {{ item.student.endDateMa }}
                                    </div>
                                </div>

                                <!-- Doctorado -->

                                <div class="row" v-show="item.student.schoolDoc">
                                    <div class="text-justify m-2" v-if="item.student.schoolDoc"><b>Escuela
                                            de
                                            Licenciatura: </b> {{ item.student.schoolDoc }} </div>
                                </div>
                                <div class="row" v-show="item.student.schoolDoc">
                                    <div class="text-justify m-2" v-if="item.student.careerDoc"><b>Nombre
                                            de
                                            la
                                            carrera: </b> {{ item.student.careerDoc }} </div>
                                </div>

                                <div style="display: flex;">
                                    <div class="row" v-show="item.student.schoolDoc">
                                        <div class="text-justify m-2" v-if="item.student.startDateDoc">
                                            <b>Inicio
                                                de
                                                la
                                                carrera: </b> {{ item.student.startDateDoc }}
                                        </div>
                                    </div>
                                    <div class="row" v-show="item.student.schoolDoc">
                                        <div class="text-justify m-2" v-if="item.student.endDateDoc">
                                            <b>Termino
                                                de la
                                                carrera: </b> {{ item.student.endDateDoc }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="item.student.status == 'universitario'"
                            style="display: flex; flex-direction: row;">
                            <div class="row">
                                <!-- Años cursados -->
                                <div class="text-justify m-2" v-if="item.student.yearActual == 'MedioAño'">
                                    <b>Años
                                        Cursados: </b> Medio Año
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearActual == 'UnAño'">
                                    <b>Años
                                        Cursados: </b> 1 Año
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearActual == 'AñoyMedio'">
                                    <b>Años
                                        Cursados: </b> 1 Año y medio
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearActual == 'DosAños'">
                                    <b>Años
                                        Cursados: </b> 2 Años
                                </div>
                                <div class="text-justify m-2"
                                    v-else-if="item.student.yearActual == 'DosAñosyMedio'">
                                    <b>Años Cursados: </b> 2 Años y medio
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Tres Años'">
                                    <b>Años
                                        Cursados: </b> 3 Años
                                </div>
                                <div class="text-justify m-2"
                                    v-else-if="item.student.yearActual == 'Tres Años y Medio'">
                                    <b>Años Cursados: </b> 3 Años y medio
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Cuatro'">
                                    <b>Años
                                        Cursados: </b> 4 Años
                                </div>
                                <div class="text-justify m-2"
                                    v-else-if="item.student.yearActual == 'Cuatro Años y Medio'"><b>Años
                                        Cursados:
                                    </b> 4
                                    Años y medio </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Cinco'">
                                    <b>Años
                                        Cursados: </b> 5 Años
                                </div>
                                <div class="text-justify m-2"
                                    v-else-if="item.student.yearActual == 'CincoAñosyMedio'">
                                    <b>Años Cursados: </b> 5 Años y medio
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearActual == 'SeisAños'">
                                    <b>Años
                                        Cursados: </b> 6 Años
                                </div>
                                <div class="text-justify m-2"
                                    v-else-if="item.student.yearActual == 'SeisAñosyMedio'">
                                    <b>Años Cursados: </b> 6 Años y medio
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearActual == 'SieteAños'">
                                    <b>Años
                                        Cursados: </b> 7 Años
                                </div>
                                <div class="text-justify m-2"
                                    v-else-if="item.student.yearActual == 'SieteAñosyMedio'">
                                    <b>Años Cursados: </b> 7 Años y medio
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearActual == '+OchoAños'">
                                    <b>Años Cursados: </b> Más de 8 años
                                </div>
                            </div>
                            <div class="row">
                                <!-- Duración de la carrera -->
                                <div class="text-justify m-2" v-if="item.student.yearTotal == 'MedioAño'">
                                    <b>Duración de la carrera: </b> Medio Año
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'UnAño'">
                                    <b>Duración de la carrera: </b> 1 Año
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'AñoyMedio'">
                                    <b>Duración de la carrera: </b> 1 Año y medio
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'DosAños'">
                                    <b>Duración
                                        de la carrera: </b> 2 Años
                                </div>
                                <div class="text-justify m-2"
                                    v-else-if="item.student.yearTotal == 'DosAñosyMedio'">
                                    <b>Duración de la carrera: </b> 2 Años y medio
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'Tres Años'">
                                    <b>Duración de la carrera: </b> 3 Años
                                </div>
                                <div class="text-justify m-2"
                                    v-else-if="item.student.yearTotal == 'Tres Años y Medio'">
                                    <b>Duración de la carrera: </b> 3 Años y medio
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'Cuatro'">
                                    <b>Duración
                                        de la carrera: </b> 4 Años
                                </div>
                                <div class="text-justify m-2"
                                    v-else-if="item.student.yearTotal == 'Cuatro Años y Medio'">
                                    <b>Duración de la carrera: </b> 4 Años y medio
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'Cinco'">
                                    <b>Duración de
                                        la carrera: </b> 5 Años
                                </div>
                                <div class="text-justify m-2"
                                    v-else-if="item.student.yearTotal == 'CincoAñosyMedio'">
                                    <b>Duración de la carrera: </b> 5 Años y medio
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'SeisAños'">
                                    <b>Duración
                                        de la carrera: </b> 6 Años
                                </div>
                                <div class="text-justify m-2"
                                    v-else-if="item.student.yearTotal == 'SeisAñosyMedio'">
                                    <b>Duración de la carrera: </b> 6 Años y medio
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'SieteAños'">
                                    <b>Duración de la carrera: </b> 7 Años
                                </div>
                                <div class="text-justify m-2"
                                    v-else-if="item.student.yearTotal == 'SieteAñosyMedio'">
                                    <b>Duración de la carrera: </b> 7 Años y medio
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearTotal == '+OchoAños'">
                                    <b>Duración de la carrera: </b> Más de 8 años
                                </div>
                            </div>
                        </div>
                        <div v-if="item.student.status == 'preuniversitario'"
                            style="display: flex; flex-direction: row;">
                            <div class="row">
                                <!-- Años cursados -->
                                <div class="text-justify m-2" v-if="item.student.yearActual == 'Medio Año'">
                                    <b>Años Cursados:</b> Medio Año
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Un Año'">
                                    <b>Años Cursados: </b> 1 Año
                                </div>
                                <div class="text-justify m-2"
                                    v-else-if="item.student.yearActual == 'Año y Medio'">
                                    <b>Años Cursados:</b> 1 Año y medio
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Dos Años'">
                                    <b>Años
                                        Cursados:</b> 2 Años
                                </div>
                                <div class="text-justify m-2"
                                    v-else-if="item.student.yearActual == 'Dos Años y Medio'">
                                    <b>Años Cursados:</b> 2 Años y medio
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Tres Años'">
                                    <b>Años
                                        Cursados:</b> 3 Años
                                </div>
                                <div class="text-justify m-2"
                                    v-else-if="item.student.yearActual == 'Tres Años y Medio'">
                                    <b>Años Cursados:</b> 3 Años y medio
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Cuatro'">
                                    <b>Años
                                        Cursados:</b> 4 Años
                                </div>
                                <div class="text-justify m-2"
                                    v-else-if="item.student.yearActual == 'Cuatro Años y Medio'"><b>Años
                                        Cursados:
                                    </b> 4
                                    Años y medio </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Cinco'">
                                    <b>Años
                                        Cursados: </b> 5 Años
                                </div>
                                <div class="text-justify m-2"
                                    v-else-if="item.student.yearActual == 'Cinco Años y Medio'">
                                    <b>Años Cursados: </b> 5 Años y medio
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Seis Años'">
                                    <b>Años
                                        Cursados: </b> 6 Años
                                </div>
                                <div class="text-justify m-2"
                                    v-else-if="item.student.yearActual == 'Seis Años y Medio'">
                                    <b>Años Cursados: </b> 6 Años y medio
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearActual == 'Siete Años'">
                                    <b>Años
                                        Cursados: </b> 7 Años
                                </div>
                                <div class="text-justify m-2"
                                    v-else-if="item.student.yearActual == 'Siete Años y Medio'">
                                    <b>Años Cursados: </b> 7 Años y medio
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearActual == '+Ocho Años'">
                                    <b>Años
                                        Cursados: </b> Más de 8 años
                                </div>

                            </div>
                            <div class="row">
                                <!-- Duración de la carrera -->
                                <div class="text-justify m-2" v-if="item.student.yearTotal == 'MedioAño'">
                                    <b>Duración
                                        de
                                        bachillerato: </b> Medio Año
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'UnAño'">
                                    <b>Duración de
                                        bachillerato: </b> 1 Año
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'AñoyMedio'">
                                    <b>Duración de bachillerato: </b> 1 Año y medio
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'DosAños'">
                                    <b>Duración
                                        de bachillerato: </b> 2 Años
                                </div>
                                <div class="text-justify m-2"
                                    v-else-if="item.student.yearTotal == 'DosAñosyMedio'">
                                    <b>Duración de bachillerato: </b> 2 Años y medio
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'Tres Años'">
                                    <b>Duración de bachillerato: </b> 3 Años
                                </div>
                                <div class="text-justify m-2"
                                    v-else-if="item.student.yearTotal == 'Tres Años y Medio'">
                                    <b>Duración de bachillerato: </b> 3 Años y medio
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'Cuatro'">
                                    <b>Duración
                                        de bachillerato: </b> 4 Años
                                </div>
                                <div class="text-justify m-2"
                                    v-else-if="item.student.yearTotal == 'Cuatro Años y Medio'">
                                    <b>Duración de bachillerato: </b> 4 Años y medio
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'Cinco'">
                                    <b>Duración de
                                        bachillerato: </b> 5 Años
                                </div>
                                <div class="text-justify m-2"
                                    v-else-if="item.student.yearTotal == 'CincoAñosyMedio'">
                                    <b>Duración de bachillerato: </b> 5 Años y medio
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'SeisAños'">
                                    <b>Duración
                                        de bachillerato: </b> 6 Años
                                </div>
                                <div class="text-justify m-2"
                                    v-else-if="item.student.yearTotal == 'SeisAñosyMedio'">
                                    <b>Duración de bachillerato: </b> 6 Años y medio
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearTotal == 'SieteAños'">
                                    <b>Duración de bachillerato: </b> 7 Años
                                </div>
                                <div class="text-justify m-2"
                                    v-else-if="item.student.yearTotal == 'SieteAñosyMedio'">
                                    <b>Duración de bachillerato: </b> 7 Años y medio
                                </div>
                                <div class="text-justify m-2" v-else-if="item.student.yearTotal == '+OchoAños'">
                                    <b>Duración de bachillerato: </b> Más de 8 años
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- Experiencia -->
                <div class="tab-pane fade" id="experiencia-tab-pane" role="tabpanel" aria-labelledby="experiencia-tab"
                    tabindex="0">
                    <div class="flex" style="padding: 2rem;">
                        <div v-for="(exp, i) in item.student.experiencia" :key="i + 1">
                            <div v-if="exp.experienciaCompany"
                                style="display: flex; flex-direction: row; justify-content: left; align-items: left; margin-top: 10px;">
                                <div class="row">
                                    <div class="text-justify m-2"> <b>Nombre de la Empresa:</b>
                                        {{ exp.experienciaCompany }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="text-justify m-2"> <b>Puesto:</b>
                                        {{ exp.experienciaPuesto }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="exp.experienciaCompany"
                                style="display: flex; flex-direction: row; justify-content: left; align-items: left;">
                                <div class="row">
                                    <div class="text-justify m-2"> <b>Salario: </b>
                                        ${{ exp.experienciaSalario }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="text-justify m-2"><b>Fecha de Inicio:</b>
                                        {{ exp.experienciaFechaInicio }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div v-if="exp.experienciaFechaFin" class="text-justify m-2"><b>Fecha de
                                            Termino:</b>
                                        {{ exp.experienciaFechaFin }}
                                    </div>
                                </div>
                            </div>
                            <div v-if="exp.experienciaCompany" style="display: flex; flex-direction: row; justify-content: left; align-items: left">
                                <div class="row">
                                    <div v-if="exp.experienciaLogros" class="text-justify m-2">
                                    <b>Actividades realizadas:</b>
                                    {{ exp.experienciaLogros }}
                                    </div>
                                </div>
                            </div>
                            <div v-else class="row mt-3 mb-2" style="margin-left: 10px;">
                                <span class="text-center">Sin Experiencia Previa</span>
                            </div>
                            <hr>
                        </div>
                    </div>
                </div>
                <!-- Idiomas -->
                <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab"
                    tabindex="0">
                    <div class="flex mt-3" style="padding: 2rem;">
                        <div class="row" v-for="(exp, i) in item.student.idiomas" :key="i + 1">
                            <div class="col-4 text-justify m-2"> <b>Idioma {{ i + 1 }}</b> {{ exp.idioma }}
                            </div>
                            <div class="col-4 text-justify m-2"> <b>Nivel:</b> {{ exp.nivel }} </div>
                        </div>
                    </div>
                </div>
                <!-- Hobbies -->
                <div class="tab-pane fade" id="disabled-tab-pane" role="tabpanel" aria-labelledby="disabled-tab"
                    tabindex="0">
                    <div class="flex mt-3" style="padding: 2rem;">
                        <div class="row mb-2" v-for="(exp, i) in item.student.hobbies" :key="i + 1">
                            <div class="col text-justify"> <b>Hobbie: </b> {{ exp.name }}</div>
                        </div>
                        <div class="row mb-2">
                            <div class="col text-justify" v-if="item.student.hobbieWrite1"> <b>Hobbie:</b>
                                {{ item.student.hobbieWrite1 }}
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col text-justify" v-if="item.student.hobbieWrite2"> <b>Hobbie:</b>
                                {{ item.student.hobbieWrite2 }}
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Documentos -->
                <div class="tab-pane fade" id="documents-tab-pane" role="tabpanel" aria-labelledby="documents-tab"
                    tabindex="0" style="padding: 1rem; max-height: 570px; overflow: scroll;">
                    <div style="height: 100vh;">
                        <div style="padding: 2rem;">
                            <div style="margin-bottom: 2rem;">
                                <span style="font-style: italic;">Progreso de llenado</span>
                                <div v-for="(item, index) in dataPostulate" :key="index">
                                    <div v-if="allDocumentsPresent(item)">
                                        <div class="progress-bar text-bg-success" style="width: 100%">100%</div>
                                    </div>
                                    <div v-else>
                                        <div class="progress-bar text-bg-danger" :style="{ width: calculateProgress(item) + '%' }">{{ calculateProgress(item) }}%</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="display:flex; flex-direction: column; align-items: flex-start; padding: 2rem;">
                            <!-- 1 Personales -->
                            <div style="display:flex; justify-content: center; align-items: center;">
                                <a v-if="item?.document_personales" class="J-btn auto" title="Hoja de datos personales" target="_blank"
                                :href="item.document_personales?.link">
                                    <box-icon name='check' color="#39811d"></box-icon>
                                    <small>Hoja de datos personales</small>
                                </a>
                                <div v-else>
                                    <box-icon name='x' color="#d9534f"></box-icon>
                                    <small>Hoja de datos personales</small>
                                </div>
                            </div>

                            <!-- 2 CV -->
                            <div style="display:flex; justify-content: center; align-items: center;">
                                <a v-if="item.student?.filecv" class="J-btn auto" title="CV" target="_blank" :href="item.student.filecv?.link">
                                    <box-icon name='check' color="#39811d"></box-icon>
                                    <small>CV</small>
                                </a>
                                <div v-else>
                                    <box-icon name='x' color="#d9534f"></box-icon>
                                    <small>CV</small>
                                </div>
                            </div>

                            <!-- 3 Acta -->
                            <div style="display:flex; justify-content: center; align-items: center;">
                                <a v-if="item?.document_acta" class="J-btn auto" title="Acta de Nacimiento" target="_blank"
                                :href="item.document_acta?.link">
                                    <box-icon name='check' color="#39811d"></box-icon>
                                    <small>Acta de nacimiento</small>
                                </a>
                                <div v-else>
                                    <box-icon name='x' color="#d9534f"></box-icon>
                                    <small>Acta de nacimiento</small>
                                </div>
                            </div>

                            <!-- 4 INE -->
                            <div style="display:flex; justify-content: center; align-items: center;">
                                <a v-if="item?.document_ine" class="J-btn auto" title="INE" target="_blank" :href="item.document_ine?.link">
                                    <box-icon name='check' color="#39811d"></box-icon>
                                    <small>INE</small>
                                </a>
                                <div v-else>
                                    <box-icon name='x' color="#d9534f"></box-icon>
                                    <small>INE</small>
                                </div>
                            </div>

                            <!-- 5 Comprobante de domicilio -->
                            <div style="display:flex; justify-content: center; align-items: center;">
                                <a v-if="item?.document_comprobante" class="J-btn auto" title="Comprobante de Domicilio" target="_blank"
                                :href="item.document_comprobante?.link">
                                    <box-icon name='check' color="#39811d"></box-icon>
                                    <small>Comprobante de domicilio</small>
                                </a>
                                <div v-else>
                                    <box-icon name='x' color="#d9534f"></box-icon>
                                    <small>Comprobante de domicilio</small>
                                </div>
                            </div>

                            <!-- 6 CURP -->
                            <div style="display:flex; justify-content: center; align-items: center;">
                                <a v-if="item?.document_curp" class="J-btn auto" title="CURP" target="_blank" :href="item.document_curp?.link">
                                    <box-icon name='check' color="#39811d"></box-icon>
                                    <small>CURP</small>
                                </a>
                                <div v-else>
                                    <box-icon name='x' color="#d9534f"></box-icon>
                                    <small>CURP</small>
                                </div>
                            </div>

                            <!-- 7 RFC -->
                            <div style="display:flex; justify-content: center; align-items: center;">
                                <a v-if="item?.document_rfc" class="J-btn auto" title="Constancia de Situación Fiscal" target="_blank"
                                :href="item.document_rfc?.link">
                                    <box-icon name='check' color="#39811d"></box-icon>
                                    <small>Constancia de Situación Fiscal</small>
                                </a>
                                <div v-else>
                                    <box-icon name='x' color="#d9534f"></box-icon>
                                    <small>Constancia de Situación Fiscal</small>
                                </div>
                            </div>

                            <!-- 8 Comprobante de último grado de estudios -->
                            <div style="display:flex; justify-content: center; align-items: center;">
                                <a v-if="item.student?.filecomprobantes" class="J-btn auto" title="Comprobante de Estudios" target="_blank"
                                :href="item.student.filecomprobantes?.link">
                                    <box-icon name='check' color="#39811d"></box-icon>
                                    <small>Comprobante de estudios (último grado de estudios)</small>
                                </a>
                                <div v-else>
                                    <box-icon name='x' color="#d9534f"></box-icon>
                                    <small>Comprobante de estudios (último grado de estudios)</small>
                                </div>
                            </div>

                            <!-- 9 NSS -->
                            <div style="display:flex; justify-content: center; align-items: center;">
                                <a v-if="item?.document_seguro" class="J-btn auto" title="Número de seguridad social" target="_blank"
                                :href="item.document_seguro?.link">
                                    <box-icon name='check' color="#39811d"></box-icon>
                                    <small>Número de seguridad social</small>
                                </a>
                                <div v-else>
                                    <box-icon name='x' color="#d9534f"></box-icon>
                                    <small>Número de seguridad social</small>
                                </div>
                            </div>

                            <!-- 10 INFONAVIT -->
                            <div style="display:flex; justify-content: center; align-items: center;">
                                <a v-if="item?.document_INFONAVIT" class="J-btn auto" title="INFONAVIT/FONACOT" target="_blank"
                                :href="item.document_INFONAVIT?.link">
                                    <box-icon name='check' color="#39811d"></box-icon>
                                    <small>INFONAVIT/FONACOT (aviso de retención o no adeudos)</small>
                                </a>
                                <div v-else>
                                    <box-icon name='x' color="#d9534f"></box-icon>
                                    <small>INFONAVIT/FONACOT (aviso de retención o no adeudos)</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Contratos -->
                <div class="tab-pane fade" id="convenios-tab-pane" role="tabpanel" aria-labelledby="convenios-tab" tabindex="0" style="padding: 1rem; max-height: 570px; overflow: scroll;">
                    <div style="height: 100vh;" v-if="enableInput2">
                        <div style="display: flex; justify-content: space-between; flex-direction: row;">
                            <button class="btn btn-primary" style="display: flex; justify-content: center; align-items: center;"
                            v-on:click="editInf2()" v-if="!item?.document_convenio1 && !item?.document_convenio2 && !item?.document_convenio3 && !item?.document_convenio4">
                                <span style="margin-right: 3px; font-size: 14px;">Subir Documentos</span>
                                <box-icon color="white" width="8" name='edit'></box-icon>
                            </button> 
                            <button class="btn btn-primary" style="display: flex; justify-content: center; align-items: center;"
                            v-on:click="editInf2()" v-else>
                                <span style="margin-right: 3px; font-size: 14px;">Editar Documentos</span>
                                <box-icon color="white" width="8" name='edit'></box-icon>
                            </button>       
                        </div>
                        <div v-show="item?.document_convenio1 || item?.document_convenio2 || item?.document_convenio3 || item?.document_convenio4"
                            style="display:flex; flex-direction: column ;justify-content: center; align-items: flex-start; padding: 2rem;">
                            <div style="margin-bottom: 2rem; width:100%">
                                <span style="font-style: italic;">Progreso de llenado</span>
                                <div v-if="item.document_convenio1 && !item.document_convenio2 && !item.document_convenio3 && !item.document_convenio4" class="progress-bar text-bg-danger" style="width: 25%">25%</div>
                                <div v-else-if="item.document_convenio2 && !item.document_convenio1 && !item.document_convenio3 && !item.document_convenio4" class="progress-bar text-bg-warning" style="width: 25%">25%</div>
                                <div v-else-if="item.document_convenio3 && !item.document_convenio1 && !item.document_convenio2 && !item.document_convenio4" class="progress-bar text-bg-warning" style="width: 25%">25%</div>
                                <div v-else-if="item.document_convenio4 && !item.document_convenio1 && !item.document_convenio2 && !item.document_convenio3" class="progress-bar text-bg-warning" style="width: 25%">25%</div>
                                <div v-else-if="item.document_convenio1 && item.document_convenio2 && !item.document_convenio3 && !item.document_convenio4" class="progress-bar text-bg-warning" style="width: 50%">50%</div>
                                <div v-else-if="item.document_convenio1 && item.document_convenio3 && !item.document_convenio2 && !item.document_convenio4" class="progress-bar text-bg-warning" style="width: 50%">50%</div>
                                <div v-else-if="item.document_convenio1 && item.document_convenio4 && !item.document_convenio2 && !item.document_convenio3" class="progress-bar text-bg-warning" style="width: 50%">50%</div>
                                <div v-else-if="item.document_convenio2 && item.document_convenio3 && !item.document_convenio1 && !item.document_convenio4" class="progress-bar text-bg-warning" style="width: 50%">50%</div>
                                <div v-else-if="item.document_convenio2 && item.document_convenio4 && !item.document_convenio1 && !item.document_convenio3" class="progress-bar text-bg-warning" style="width: 50%">50%</div>
                                <div v-else-if="item.document_convenio3 && item.document_convenio4 && !item.document_convenio1 && !item.document_convenio2" class="progress-bar text-bg-warning" style="width: 50%">50%</div>
                                <div v-else-if="item.document_convenio1 && item.document_convenio2 && item.document_convenio3 && !item.document_convenio4" class="progress-bar text-bg-warning" style="width: 75%">75%</div>
                                <div v-else-if="item.document_convenio1 && item.document_convenio2 && item.document_convenio4 && !item.document_convenio3" class="progress-bar text-bg-warning" style="width: 75%">75%</div>
                                <div v-else-if="item.document_convenio1 && item.document_convenio3 && item.document_convenio4 && !item.document_convenio2" class="progress-bar text-bg-warning" style="width: 75%">75%</div>
                                <div v-else-if="item.document_convenio2 && item.document_convenio3 && item.document_convenio4 && !item.document_convenio1" class="progress-bar text-bg-warning" style="width: 75%">75%</div>
                                <div v-else-if="item.document_convenio1 && item.document_convenio2 && item.document_convenio3 && item.document_convenio4" class="progress-bar text-bg-success" style="width: 100%">100%</div>
                            </div>
                            <div style="display:flex; justify-content: center; align-items: center;"
                                v-if="item?.document_convenio1">
                                <a class="J-btn auto" title="Contrato" target="_blank"
                                    :href="item?.document_convenio1?.link">
                                    <box-icon name='check' color="#39811d"></box-icon>
                                    <small>Contrato</small>
                                </a>
                            </div>
                            <div style="display:flex; justify-content: center; align-items: center;"
                                v-if="item?.document_convenio2">
                                <a class="J-btn auto" title="Entrega de Equipo" target="_blank"
                                    :href="item?.document_convenio2?.link">
                                    <box-icon name='check' color="#39811d"></box-icon>
                                    <small>Entrega de Equipo</small>
                                </a>
                            </div>
                            <div style="display:flex; justify-content: center; align-items: center;"
                                v-if="item?.document_convenio3">
                                <a class="J-btn auto" title="Alta IMSS" target="_blank"
                                    :href="item?.document_convenio3?.link">
                                    <box-icon name='check' color="#39811d"></box-icon>
                                    <small>Alta IMSS</small>
                                </a>
                            </div>
                            <div style="display:flex; justify-content: center; align-items: center;"
                                v-if="item?.document_convenio4">
                                <a class="J-btn auto" title="Carta de confidencialidad, privacidad y otros" target="_blank"
                                    :href="item?.document_convenio4?.link">
                                    <box-icon name='check' color="#39811d"></box-icon>
                                    <small>Carta de confidencialidad, privacidad y otros</small>
                                </a>
                            </div>
                        </div>
                        <div v-show="!item?.document_convenio1 && !item?.document_convenio2 && !item?.document_convenio3 && !item?.document_convenio4">
                            <div style="display: flex; justify-content: center; align-items: center;">
                                <h3>Aún no tienes contratos para ver</h3>
                                <img src="/images/profile.jpg" alt="Logo Company" class="rounded-circle" loading="eager"
                                    style="width: 30%; aspect-ratio: 1/1; object-fit: cover; object-position: center;">
                            </div>
                        </div>
                    </div>
                    <div v-else-if="!enableInput2">
                        <h5 style="text-align: center;">SUBIR O ACTUALIZAR DOCUMENTOS</h5>
                        <div style="display: flex; justify-content: center; margin-bottom: 2rem;">
                            <div style="flex: 1">
                                <UploadPartials title="Contrato" @data="documentConvenio1" />
                            </div>
                            <div style="flex: 1">
                                <UploadPartials title="Entrega de Equipo" @data="documentConvenio2" />
                            </div>
                            <div style="flex: 1">
                                <UploadPartials title="Alta IMSS" @data="documentConvenio3" />
                            </div>
                            <div style="flex: 1">
                                <UploadPartials title="Carta de confidencialidad, privacidad y otros" @data="documentConvenio4" />
                            </div>
                        </div>
                        <div class="d-grid gap-2 col-6 mx-auto">
                            <button class="btn btn-primary" :disabled="response.loading" type="button" @click="updateDocuments()">
                                Subir Documentos
                                <div v-show="response.loading" class="spinner-border spinner-border-sm" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </button>
                            <button id="change" class="btn btn-danger me-md-2" type="button" v-on:click="editInf2()">
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script lang="js">
import * as ClientService from "../../services/client"
import UploadPartials from "@/components/partials/upload.partials.vue";

import moment from 'moment-timezone';
moment.locale("es");

export default {
    data: () => ({
        moment: moment,
        userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        file_name: null,
        file: null,
        dataPostulate: [],
        dataPostulantetemp: [],
        informationPostulante: {},
        informationStudent: {},
        informationUser: {},
        searchInput2: "",
        id: "",
        show: "",
        idPostulante: "",
        statusPostulante: "",
        document_convenio1: "",
        document_convenio2: "",
        document_convenio3: "",
        document_convenio4: "",
        idAdministration: "",
        position: "",
        company: "",
        level: "",
        department: "",
        salary: "0",
        rfc: "",
        nss: "",
        bank: "",
        bankAccount: "",
        dateHired: Date,
        dateFirstPeriod: Date,
        dateSecondPeriod: Date,
        comments: "",
        infonavitCredit: "",
        statusActually: "",
        antiquityPractices: "",
        emailCorporative: "",
        nameContact: "",
        relationship: "",
        numberContact: "",
        nameContactTwo: "",
        relationshipTwo: "",
        numberContactTwo: "",
        response: {
            loading: false
        },
        id_vacante: "",
        enableInput: true,
        enableInput2: true
    }),
    mounted() {
        this.id = this.$route.params.vacante;
        const postulante = this.$route.params.postulante;
        this.id_vacante = this.$route.params.id;
        this.getPostulates(this.id, postulante);
    },
    components: {
        UploadPartials
    },

    methods: {
        async getPostulates(vacante, postulante) {
            try {
                let resp = await ClientService.CandidateContratado({ id: vacante, postulante: postulante });
                let { data } = resp.data;
                this.dataPostulate = data;
                let { student, user, _id, status } = data;
                this.idPostulante = _id;
                this.informationStudent = student;
                this.informationUser = user;
                this.statusPostulante = status;
            } catch (e) {
                console.log(e.message);
            }
        },
        //TODO:Ordenar Postulantes Por Edad
        sortEdad(postulante) {
            postulante.sort((a, b) => {
                if (a.student.date == b.student.date) {
                    return 0;
                }
                if (a.student.date > b.student.date) {
                    return -1;
                }
                return 1;
            });
            return postulante;
        },
        //TODO: Calcular Edad De Postulantes
        getEdad(dateString) {
            let hoy = new Date()
            let fechaNacimiento = new Date(dateString)
            let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
            let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
            if (
                diferenciaMeses < 0 ||
                (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
            ) {
                edad--
            }
            return edad
        },

        openLinkedin(url) {
            let word = "https://"
            url.includes(word) ? window.open(url) : window.open("https://" + url);
        },

        async documentConvenio1(data) {
            this.document_convenio1 = data;
        },
        async documentConvenio2(data) {
            this.document_convenio2 = data;
        },
        async documentConvenio3(data) {
            this.document_convenio3 = data;
        },
        async documentConvenio4(data) {
            this.document_convenio4 = data;
        },
        async DataAdministration() {
            const confirmed = window.confirm("¿Estás seguro de guardar o actualizar la información?");
            if (!confirmed) {
                return;
            }
            let dataAdministration = {
                _id_vacante: this.$route.params.vacante,
                _id_user: this.$route.params.postulante,
                idAdministration: this.idAdministration,
                position: this.position,
                mentor: this.mentor,
                company: this.company,
                level: this.level,
                department: this.department,
                salary: this.salary,
                rfc: this.rfc,
                nss: this.nss,
                bank: this.bank,
                bankAccount: this.bankAccount,
                dateHired: this.dateHired,
                dateFirstPeriod: this.dateFirstPeriod,
                dateSecondPeriod: this.dateSecondPeriod,
                comments: this.comments,
                infonavitCredit: this.infonavitCredit,
                statusActually: this.statusActually,
                antiquityPractices: this.antiquityPractices,
                emailCorporative: this.emailCorporative,
                nameContact: this.nameContact,
                relationship: this.relationship,
                numberContact: this.numberContact,
                nameContactTwo: this.nameContactTwo,
                relationshipTwo: this.relationshipTwo,
                numberContactTwo: this.numberContactTwo,
            };
            const isNewData = !dataAdministration._id_vacante || !dataAdministration._id_user;
            try {
                const response = await ClientService.UpdateDataAdminstration(dataAdministration);
                this.$swal({
                    position: "top-center",
                    icon: "success",
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 2500,
                });
                this.response.loading = false;
                window.location.reload();
            } catch (error) {
                this.$swal({
                    position: "top-center",
                    icon: "error",
                    title: error.message,
                    showConfirmButton: false,
                    timer: 2500,
                });
                this.response.loading = false;
            }
        },

        updateDocuments() {
            this.response.loading = true;
            let updateDocumentos = {
                _id_vacante: this.$route.params.vacante,
                _id_user: this.$route.params.postulante,
                document_convenio1: this.document_convenio1,
                document_convenio2: this.document_convenio2,
                document_convenio3: this.document_convenio3,
                document_convenio4: this.document_convenio4,
            };
            ClientService.UploadConveniosCompany(updateDocumentos).then((response) => {
                this.$swal({
                    position: "top-center",
                    icon: "success",
                    title: response.data.message,
                    showConfirmButton: false,
                    timer: 2500,
                });

                this.response.loading = false;
                window.location.reload();
            }).catch((e) => {
                this.$swal({
                    position: "top-center",
                    icon: "error",
                    title: e.response.data.message,
                    showConfirmButton: false,
                    timer: 2500,
                });
                this.response.loading = false;
            });
        },

        editInf() {
            this.enableInput == true ? this.enableInput = false : this.enableInput = true;
            this.datosAdministracion();
        },
        editInf2() {
            this.enableInput2 == true ? this.enableInput2 = false : this.enableInput2 = true;
        },
        datosAdministracion(){
        this.dataPostulate.forEach((postulate) => {
            this.idAdministration = postulate.idAdministration;
            this.position = postulate.position;
            this.company = postulate.company
            this.level = postulate.level
            this.department = postulate.department
            this.salary = postulate.salary
            this.rfc = postulate.rfc
            this.nss = postulate.nss
            this.bank = postulate.bank
            this.bankAccount = postulate.bankAccount
            if (postulate.dateHired) {
                const formattedDate1 = postulate.dateHired.split('T')[0];
                this.dateHired = formattedDate1;
            } else {
                this.dateHired = '';
            }
            if (postulate.dateFirstPeriod) {
                const formattedDate2 = postulate.dateFirstPeriod.split('T')[0];
                this.dateFirstPeriod = formattedDate2;
            } else {
                this.dateFirstPeriod = '';
            }
            if (postulate.dateSecondPeriod) {
                const formattedDate3 = postulate.dateSecondPeriod.split('T')[0];
                this.dateSecondPeriod = formattedDate3;
            } else {
                this.dateSecondPeriod = '';
            }
            this.comments = postulate.comments
            this.infonavitCredit = postulate.infonavitCredit
            this.statusActually = postulate.statusActually
            this.antiquityPractices = postulate.antiquityPractices
            this.emailCorporative = postulate.emailCorporative
            this.nameContact = postulate.nameContact
            this.relationship = postulate.relationship
            this.numberContact = postulate.numberContact
            this.nameContactTwo = postulate.nameContactTwo
            this.relationshipTwo = postulate.relationshipTwo
            this.numberContactTwo = postulate.numberContactTwo 
            });
        },
        formatBeca() {
          const becaDeValue = this.salary.trim();
          if (becaDeValue !== "") {
            const parsedValue = parseInt(becaDeValue);
            if (!isNaN(parsedValue)) {
              this.salary = parsedValue.toLocaleString("es-MX", {
                style: "currency",
                currency: "MXN",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              });
            }
          }
        },
        onlyNumber(event) {
          const charCode = event.charCode;
          if (charCode < 48 || charCode > 57) {
            event.preventDefault();
          }
        },
        allDocumentsPresent(item) {
            return item.student.filecv &&
                item.student.filecomprobantes &&
                item.document_acta &&
                item.document_ine &&
                item.document_comprobante &&
                item.document_curp &&
                item.document_rfc &&
                item.document_seguro &&
                item.document_personales &&
                item.document_INFONAVIT; 
       },
        // Calcula el progreso para un postulante específico
        calculateProgress(item) {
            const totalDocuments = 10; // Total de documentos requeridos
            let presentDocuments = 0;
            if (item.student.filecv) presentDocuments++;
            if (item.student.filecomprobantes) presentDocuments++;
            if (item.document_acta) presentDocuments++;
            if (item.document_ine) presentDocuments++;
            if (item.document_comprobante) presentDocuments++;
            if (item.document_curp) presentDocuments++;
            if (item.document_rfc) presentDocuments++;
            if (item.document_seguro) presentDocuments++;
            if (item.document_personales) presentDocuments++;
            if (item.document_INFONAVIT) presentDocuments++; 
            // Calcula el progreso como un número redondeado al entero siguiente
            return Math.ceil((presentDocuments / totalDocuments) * 100);
        },
        comeback(){
          this.$router.push('/administration/mycompany')
        },
        getAntiguedad(dateHired, dateSecondPeriod) {
            const startDate = moment(dateHired).startOf('day');
            let endDate = dateSecondPeriod ? moment(dateSecondPeriod).startOf('day') : moment().startOf('day'); 
            if (dateSecondPeriod && endDate < startDate) {
                return 0;
            }
            if (!dateSecondPeriod) {
                endDate = endDate.subtract(1, 'day'); 
            }
            const tiempoDiferencia = endDate.diff(startDate, 'days');
            return Math.max(tiempoDiferencia, 0); 
        },
    },
};
</script>

<style land="scss" scoped>
@import "../../styles/informacionpostulantes.scss";
</style>